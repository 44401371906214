<template>
    <div class="edit-view">
        <page-title>
            <el-button @click="$router.go(-1)" :disabled="saving">取消</el-button>
            <!-- <el-button @click="onDelete" :disabled="saving" type="danger" v-if="formData.id"> 删除 </el-button>
            <el-button @click="onSave" :loading="saving" type="primary">保存</el-button> -->
        </page-title>
        <div class="edit-view__content-wrapper">
            <div class="edit-view__content-section">
                <el-form
                    :model="formData"
                    :rules="rules"
                    ref="form"
                    label-width="94px"
                    label-position="right"
                    size="small"
                    style="max-width: 500px"
                >
                    <el-form-item prop="userId" label="用户ID">
                        <el-input-number type="number" v-model="formData.userId" disabled></el-input-number>
                    </el-form-item>
                    <el-form-item prop="collectionId" label="藏品ID">
                        <el-input-number type="number" v-model="formData.collectionId" disabled></el-input-number>
                    </el-form-item>
                    <el-form-item prop="qty" label="数量">
                        <el-input v-model="formData.qty" disabled></el-input>
                    </el-form-item>
                    <el-form-item prop="name" label="名称">
                        <el-input v-model="formData.name" disabled></el-input>
                    </el-form-item>
                    <el-form-item prop="pic" label="图片">
                        <object-upload :value="pic" disabled></object-upload>
                    </el-form-item>
                    <el-form-item prop="minterId" label="铸造者ID">
                        <el-input-number type="number" v-model="formData.minterId" disabled></el-input-number>
                    </el-form-item>
                    <el-form-item prop="price" label="价格">
                        <el-input-number type="number" v-model="formData.price" disabled></el-input-number>
                    </el-form-item>
                    <el-form-item prop="gasPrice" label="gas费">
                        <el-input-number type="number" v-model="formData.gasPrice" disabled></el-input-number>
                    </el-form-item>
                    <el-form-item prop="totalPrice" label="总价">
                        <el-input-number type="number" v-model="formData.totalPrice" disabled></el-input-number>
                    </el-form-item>
                    <el-form-item prop="status" label="状态">
                        <el-select v-model="formData.status" clearable filterable placeholder="请选择" disabled>
                            <el-option
                                v-for="item in statusOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="payMethod" label="支付方式">
                        <el-select v-model="formData.payMethod" clearable filterable placeholder="请选择" disabled>
                            <el-option
                                v-for="item in payMethodOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="transactionId" label="交易ID">
                        <el-input v-model="formData.transactionId" disabled></el-input>
                    </el-form-item>
                    <el-form-item prop="payTime" label="支付时间">
                        <el-date-picker
                            v-model="formData.payTime"
                            type="datetime"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            placeholder="选择日期时间"
                            disabled
                        >
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item prop="txHash" label="链上hash">
                        <el-input v-model="formData.txHash" disabled></el-input>
                    </el-form-item>
                    <el-form-item prop="gasUsed" label="消耗gas">
                        <el-input v-model="formData.gasUsed" disabled></el-input>
                    </el-form-item>
                    <el-form-item class="form-submit">
                        <!-- <el-button @click="onSave" :loading="saving" type="primary"> 保存 </el-button>
                        <el-button @click="onDelete" :disabled="saving" type="danger" v-if="formData.id">
                            删除
                        </el-button> -->
                        <el-button @click="$router.go(-1)" :disabled="saving">取消</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'OrderEdit',
    created() {
        if (this.$route.query.id) {
            this.$http
                .get('order/get/' + this.$route.query.id)
                .then(res => {
                    this.formData = res;
                })
                .catch(e => {
                    console.log(e);
                    this.$message.error(e.error);
                });
        }
    },
    data() {
        return {
            saving: false,
            formData: {},
            rules: {},
            statusOptions: [
                { label: '未支付', value: 'NOT_PAID' },
                { label: '已支付，处理中', value: 'PROCESSING' },
                { label: '已完成', value: 'FINISH' },
                { label: '已取消', value: 'CANCELLED' }
            ],
            payMethodOptions: [
                { label: '微信', value: 'WEIXIN' },
                { label: '支付宝', value: 'ALIPAY' }
            ]
        };
    },
    computed: {
        pic() {
            return (this.formData.pic || [])[0] || {};
        }
    },
    methods: {
        onSave() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.submit();
                } else {
                    return false;
                }
            });
        },
        submit() {
            let data = { ...this.formData };

            this.saving = true;
            this.$http
                .post('/order/save', data, { body: 'json' })
                .then(res => {
                    this.saving = false;
                    this.$message.success('成功');
                    this.$router.go(-1);
                })
                .catch(e => {
                    console.log(e);
                    this.saving = false;
                    this.$message.error(e.error);
                });
        },
        onDelete() {
            this.$confirm('删除将无法恢复，确认要删除么？', '警告', { type: 'error' })
                .then(() => {
                    return this.$http.post(`/order/del/${this.formData.id}`);
                })
                .then(() => {
                    this.$message.success('删除成功');
                    this.$router.go(-1);
                })
                .catch(e => {
                    if (e !== 'cancel') {
                        console.log(e);
                        this.$message.error((e || {}).error || '删除失败');
                    }
                });
        }
    }
};
</script>
<style lang="less" scoped></style>
